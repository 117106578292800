<div class="error-message" *ngIf="errorMessage">
  {{ errorMessage }}
</div>

<section>

  <div id="scanner-container">
    <!-- QuaggaJS will populate this element -->
  </div>

  <div class="instructions" *ngIf="false">
    This is a companion app for the blog post located <a target="_blank" href="https://blog.classycode.com/">here</a>.
    The source code is available on <a target="_blank" href="https://github.com/classycodeoss/mobile-scanning-demo">Github</a>.
  </div>

  <div class="shopping-cart">

    <span *ngIf="productsScanned.length == 0">Please scan barcode</span>

    <ul>
      <li *ngFor="let product of productsScanned">{{ product.ItemName}}(${{ product.UnitPrice }})</li>
    </ul>

  </div>

  <footer>
      <button (click)="handleCloseClickEvent($event)">Close</button>
  </footer>
  
</section>
